import { imageBasePath } from "lib/utils/imageUtils";
import { useRouter } from "next/router";
import Layout from "../Layout";
import MainFooter from "../MainFooter";

const NotFound = () => {
  const router = useRouter();

  return (
    <Layout title="Not Found" showNavLinks invertLogo={false} showBackground>
      {() => {
        return (
          <div className="mt-70">
            <div className="mih-h-screen">
              <div>
                <img
                  src={`${imageBasePath}/assets/images/knowledge-base/not-found.svg`}
                  alt="not found"
                  className="w-2/3 md:w-1/3 mx-auto"
                />
                <div className="md:max-w-xl max-w-xs mx-auto my-6">
                  <h1 className="font-bold text-xl text-center md:text-2xl">
                    Oops! The page you were looking for doesn’t exist.
                  </h1>
                  <p className="text-gray-550 text-center mt-4 mb-8 text-l md:text-xl">
                    You may have mistyped the address or the page may have moved
                  </p>
                  <div className="flex justify-center">
                    <button
                      onClick={() => router.push("/")}
                      className="w-2/3 bg-primary text-white font-bold rounded text-center py-3"
                    >
                      Go to homepage
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <MainFooter />
          </div>
        );
      }}
    </Layout>
  );
};

export default NotFound;
